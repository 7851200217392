<div class="technical-container">
  <div class="trends">
    <h3 class="title">Trends</h3>
    <div class="short-term-trends technical-row">
      <div class="description">
        <h3>Short Term Trend (20)</h3>
        <p>Stock price above/below 20-day moving average</p>
      </div>
      <mat-icon
        [svgIcon]="directionClass[technical.shortTermTrend] + '-trend-arrow-icon'"
        class="trend-direction"
      ></mat-icon>
    </div>
    <div class="medium-term-trends technical-row">
      <h3>Medium Term Trend (50)</h3>
      <mat-icon
        [svgIcon]="directionClass[technical.mediumTermTrend] + '-trend-arrow-icon'"
        class="trend-direction"
      ></mat-icon>
    </div>
    <div class="long-term-trends technical-row">
      <h3>Long Term Trend (200)</h3>
      <mat-icon
        [svgIcon]="directionClass[technical.longTermTrend] + '-trend-arrow-icon'"
        class="trend-direction"
      ></mat-icon>
    </div>
  </div>

  <div class="correlation">
    <h3 class="title">Correlation</h3>
    <div class="correlation-wrapper">
      <div class="correlation-bar-wrapper">
        <div
          [style.left]="getCorrelationMarkerPosition(technical.correlation)"
          class="correlation-bar-position"
        >
          <div class="correlation-description">
            <p>Marker Correlation</p>
            <p>{{ technical.correlation }}</p>
          </div>
          <div class="marker"></div>
        </div>
        <div class="correlation-bar">
          <div class="low-bar bar">
            <p>LOW</p>
            <div class="arrow">&#11015;</div>
          </div>
          <p>NO</p>
          <div class="high-bar bar">
            <span class="arrow">&#11014;</span>
            <p>HIGH</p>
          </div>
        </div>
        <div class="correlation-bar-values">
          <p>-1</p>
          <p>0</p>
          <p>1</p>
        </div>
      </div>
    </div>
  </div>
  <div class="ranges">
    <h3 class="title">Ranges</h3>
    <div class="ranges-wrapper">
      <div class="range-row technical-row">
        <h3>52-Weel Low</h3>
        <h3>{{ technical.ranges.weekLow }}</h3>
      </div>
      <div class="range-row technical-row">
        <h3>52-Week High</h3>
        <h3>{{ technical.ranges.weekHigh }}</h3>
      </div>
      <div class="range-row technical-row">
        <h3>Average Daily Range (7 days)</h3>
        <h3>{{ technical.ranges.dailtyRange }}</h3>
      </div>
      <div class="range-row technical-row">
        <h3>Average Volume</h3>
        <h3>{{ technical.ranges.averageVolume }}</h3>
      </div>
    </div>
  </div>
  <div class="performance">
    <h3 class="title">Performance</h3>
    <div class="performance-wrapper">
      @for (item of technical.performance; track item.range) {
        <div
          [ngClass]="{ negative: item.value < 0 }"
          class="performance-block"
        >
          <p>{{ performanceName[item.range] }}</p>
          <div class="performance-value">
            <p>{{ item.value }}%</p>
            <app-progress-bar
              [barColor]="item.value > 0 ? 'success' : 'danger'"
              [value]="item.value * 2"
            ></app-progress-bar>
          </div>
        </div>
      }
    </div>
  </div>
</div>
