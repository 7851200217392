export const valuation = [
  {
    title: 'PE',
    description:
      "Price-to-Earnings ratio) measures how much investors are willing to pay for each dollar of a company's earnings.",
    industry: 34.56,
    sector: 50,
  },
  {
    title: 'Fwd Pe',
    description:
      'The forward price/earnings ratio of the company, comparing the current price to the earning per share estimate for the next year',
    industry: 28.76,
    sector: 45,
  },
  {
    title: 'P/FCF',
    description: 'Compares a company’s stock price to its free cash flow, indicating financial flexibility.',
    industry: 38.86,
    sector: 15,
  },
  {
    title: 'EV/EBITDA',
    description:
      'Measures a company’s valuation relative to its operating earnings, useful for comparing companies with different capital structures.',
    industry: 26.11,
    sector: 40,
  },
];

export const health = [
  {
    title: 'P/B',
    description: 'Compares a stock’s price to its book value, indicating whether it’s over- or under-valued.',
    industry: 54.27,
    sector: 45,
  },
  {
    title: 'P/tB',
    description: 'Similar to P/B but excludes intangible assets like goodwill.',
    industry: 54.27,
    sector: 40,
  },
];

export const profitability = [
  {
    title: 'P/S',
    description:
      'Compares a company’s stock price to its revenue, useful for evaluating startups or companies with low earnings.',
    industry: 0.92,
    sector: 100,
  },
  {
    title: 'PEG',
    description: 'Adjusts the P/E ratio for expected earnings growth; a PEG under 1 suggests potential undervaluation.',
    industry: 3.56,
    sector: 10,
  },
];
