import { ChangeDetectionStrategy, Component, computed, Injector, input, ViewEncapsulation } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import moment from 'moment';
import { BehaviorSubject, combineLatest, of, startWith } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { MomentDateTimeFormats } from '@const';
import { AiNewsItemModel, NewsService } from '@s/news.service';

@Component({
  selector: 'app-ai-news',
  templateUrl: './ai-news.component.html',
  styleUrl: './ai-news.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinner],
})
export class AiNewsComponent {
  public isActive = input.required<boolean>();
  public companyName = input.required<string | null>();

  protected readonly updateNewsDebounceTimeMs = 200;

  protected readonly updateNewsSignal$ = new BehaviorSubject<number>(Date.now());
  protected readonly newsFromStream$ = combineLatest([
    toObservable(this.companyName).pipe(debounceTime(400)),
    // it should re-request news when the component becomes active
    toObservable(this.isActive),
    this.updateNewsSignal$.pipe(debounceTime(this.updateNewsDebounceTimeMs)),
  ]).pipe(
    switchMap(([companyName, isActive]) => {
      if (!companyName) {
        return of({ status: 'success', news: [] as AiNewsItemModel[] });
      }

      if (!isActive) {
        return of({ status: 'pending', news: [] as AiNewsItemModel[] });
      }

      const startDate = moment().subtract(4, 'days').format(MomentDateTimeFormats.ServerDate);
      const endDate = moment().add(1, 'days').format(MomentDateTimeFormats.ServerDate);

      return this.newsService.getAiNewsAsStream(companyName, startDate, endDate).pipe(
        map((news) => {
          return {
            status: 'success',
            news: news.currentNews,
          };
        }),
        startWith({ status: 'pending', news: [] as AiNewsItemModel[] }),
        catchError(() => {
          return of({ status: 'error', news: [] as AiNewsItemModel[] });
        }),
      );
    }),
    startWith({ status: 'pending', news: [] as AiNewsItemModel[] }),
  );

  protected readonly newsFromStream = toSignal(this.newsFromStream$, { injector: this.injector });

  protected readonly currentNews = computed(() => {
    const isActive = this.isActive();

    if (!isActive) {
      return { status: 'pending', news: [] };
    }

    const news = this.newsFromStream();

    const extendedNews = news.news
      .sort((a, b) => new Date(b.publication_date).getTime() - new Date(a.publication_date).getTime())
      .map((item) => {
        const indexedLinks = item.link_indices
          .map((linkIndex, index) => {
            const link = item.links[index];

            if (!link) {
              return null;
            }

            return {
              linkIndex,
              link,
            };
          })
          .filter((item) => Boolean(item));

        return {
          ...item,
          indexedLinks,
        };
      });

    return { status: news.status, news: extendedNews };
  });

  constructor(
    private injector: Injector,
    private newsService: NewsService,
  ) {}

  ngOnInit(): void {}

  public updateNews(): void {
    const currentNews = this.currentNews();

    // do not re-request news if it is waiting for the response
    if (currentNews.status === 'pending') {
      return;
    }

    this.updateNewsSignal$.next(Date.now());
  }

  protected onClickLink(link: string): void {
    if (!link) {
      return;
    }

    window.open(link, '_blank');
  }
}
