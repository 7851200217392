import { effect, Injectable, Injector } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { interval } from 'rxjs';

interface CacheItem<T> {
  value: T;
  expiry: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class MemoryCacheService {
  private cache: Map<string, CacheItem<unknown>> = new Map();

  private readonly clearCacheIntervalMin = 10;
  private readonly clearCacheSignal = toSignal(interval(this.clearCacheIntervalMin * 1000 * 60), {
    injector: this.injector,
  });

  constructor(private injector: Injector) {
    // clear expired items every [clearCacheIntervalMin] minutes
    effect(() => {
      this.clearCacheSignal();
      const now = Date.now();

      for (const [key, item] of this.cache.entries()) {
        if (item.expiry && item.expiry < now) {
          this.cache.delete(key);
        }
      }
    });
  }

  public set<T>(key: string, value: T, ttl?: number): void {
    const expiry = ttl ? Date.now() + ttl : null;
    this.cache.set(key, { value, expiry });
  }

  public get<T>(key: string): T | null {
    const item = this.cache.get(key);

    if (!item) {
      return null;
    }

    if (item.expiry && item.expiry < Date.now()) {
      this.cache.delete(key);
      return null;
    }

    return item.value as T;
  }

  public clear(key?: string): void {
    if (key) {
      this.cache.delete(key);
    } else {
      this.cache.clear();
    }
  }

  public keys(): string[] {
    return Array.from(this.cache.keys());
  }
}
