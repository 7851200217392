import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ProgressBarModule } from '@c/common/progress-bar/progress-bar.module';
import { technicalData } from './technical.data';
import { DirectionClass, PerformanceName } from './technical.model';

@Component({
  selector: 'app-technical',
  standalone: true,
  imports: [MatIconModule, CommonModule, ProgressBarModule],
  templateUrl: './technical.component.html',
  styleUrl: './technical.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnicalComponent {
  protected readonly technical = technicalData;
  protected readonly directionClass = DirectionClass;
  protected readonly performanceName = PerformanceName;

  protected getCorrelationMarkerPosition(correlation: number): string {
    return ((correlation + 1) / 2) * 100 + '%';
  }
}
