import { Injectable } from '@angular/core';
import { DeepWriteable, RawStudyMetaInfoId, StudyPlotInformation } from '@chart/charting_library';
import { StorageKeys } from '@const';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService } from '@s/observable.service';
import moment from 'moment';

@Injectable()
export class Rsi {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createRsiIndicator(
    PineJS,
    observableService: ObservableService,
    localStorageService: LocalStorageService,
    name,
    displayName,
  ) {
    return {
      name,
      metainfo: {
        _metainfoVersion: 40,
        id: `${name}@tv-basicstudies-1` as RawStudyMetaInfoId,
        format: {
          type: 'price' as DeepWriteable<'price'>,
          precision: 2,
        },
        scriptIdPart: '',
        name,
        description: name,
        shortDescription: displayName,
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
          { id: 'plot_0', type: 'line' },
          { id: 'plot_1', type: 'colorer', palette: 'palette_0', target: 'plot_0' },
        ] as DeepWriteable<Readonly<StudyPlotInformation>>[],
        palettes: {
          palette_0: {
            colors: [{ name: 'Color 0' }, { name: 'Color 1' }],

            valToIndex: {
              0: 0,
              1: 1,
            },
          },
        },
        defaults: {
          styles: {
            plot_0: {
              linestyle: 0,
              visible: true,
              linewidth: 2,
              plottype: 5,
              trackPrice: false,
              transparency: 70,
              color: '#2196F3', // blue color
            },
          },
          palettes: {
            palette_0: {
              colors: {
                0: {
                  color: '#EF534F', // red
                  width: 1,
                  style: 0,
                },
                1: {
                  color: '#33A69A', // green
                  width: 1,
                  style: 0,
                },
              },
            },
          },
          precision: 0, // Precision is set to one digit, e.g. 777.7
          inputs: {
            quick_sma_period: 50,
            medium_sma_period: 100,
            slow_sma_period: 200,
          },
        },

        styles: {
          plot_0: {
            title: 'title', // Output name will be displayed in the Style window
            histogramBase: 1,
          },
        },
        inputs: [],
      },

      constructor: function (): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/no-unused-vars
        (this.init = async function (context, inputCallback): Promise<void> {
          const security_id = observableService.symbol.getValue();
          const storageKey = `${StorageKeys.ProcessedData}_${security_id}`;
          this.processedData = localStorageService.getFromMemory(storageKey);
        }),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-function-return-type
          (this.main = function (context, inputCallback) {
            const date = moment(PineJS.Std.time(context)).tz('America/New_York');

            if (isNaN(+date) || !this.processedData) {
              return;
            }

            const barDate = moment(date).format('YYYY-MM-DD');
            const item = this.processedData[barDate] ?? {};

            if (!item?.rsi_color) {
              return [];
            } else {
              const color = item.rsi_color === 'G' ? 1 : 0;
              return [3, color];
            }
          });
      },
    };
  }
}
