import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Injector,
  input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { BehaviorSubject, combineLatest, of, startWith } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { GroupedCompanyNewsModel } from '@c/income-statement/company-news/company-news.model';
import { MarketTimeService } from '@s/market-time.service';
import { NewsItemModel, NewsService } from '@s/news.service';

@Component({
  selector: 'app-company-news',
  templateUrl: './company-news.component.html',
  styleUrl: './company-news.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinner],
})
export class CompanyNewsComponent implements OnInit {
  public isActive = input.required<boolean>();
  public companyName = input.required<string | null>();

  protected readonly maxHistoryLengthWorkingDays = 4; // 4 + current
  protected readonly updateNewsDebounceTimeMs = 200;

  protected readonly updateNewsSignal$ = new BehaviorSubject<number>(Date.now());
  protected readonly news$ = combineLatest([
    toObservable(this.companyName),
    // it should re-request news when the component becomes active
    toObservable(this.isActive),
    this.updateNewsSignal$.pipe(debounceTime(this.updateNewsDebounceTimeMs)),
  ]).pipe(
    switchMap(([companyName, isActive]) => {
      if (!companyName) {
        return of({ status: 'success', news: [] as NewsItemModel[] });
      }

      if (!isActive) {
        return of({ status: 'pending', news: [] as NewsItemModel[] });
      }

      return this.newsService.getNews(companyName).pipe(
        map((news) => {
          return {
            status: 'success',
            news,
          };
        }),
        startWith({ status: 'pending', news: [] as NewsItemModel[] }),
        catchError(() => {
          return of({ status: 'error', news: [] as NewsItemModel[] });
        }),
      );
    }),
    startWith({ status: 'pending', news: [] as NewsItemModel[] }),
  );

  protected readonly news = toSignal(this.news$, { injector: this.injector });
  protected readonly currentNews = computed(() => {
    const isActive = this.isActive();

    if (!isActive) {
      return { status: 'pending', news: [] };
    }

    const news = this.news();
    const currentDate = new Date();
    const lastWorkingDayTimestamp = this.marketTimeService
      .getNextWorkingDay(-1 * this.maxHistoryLengthWorkingDays)
      .startOf('day')
      .valueOf();

    const filteredByDateNews = news.news.filter((item) => {
      const pubDate = new Date(item.pubDate);

      return pubDate.getTime() > lastWorkingDayTimestamp;
    });

    const sortedByDateNews = filteredByDateNews.sort((a, b) => {
      return new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime();
    });

    const extendedNews = sortedByDateNews.map((item) => {
      const pubDate = new Date(item.pubDate);
      const diffInMinutes = Math.floor((currentDate.getTime() - pubDate.getTime()) / (1000 * 60));

      const minutesInHour = 60;
      const minutesInTwoHours = 2 * minutesInHour; // 120
      const minutesInDay = 24 * 60; // 1440
      const minutesInTwoDays = 2 * minutesInDay; // 2880

      let timeAgo = '';

      if (diffInMinutes < 1) {
        timeAgo = '< 1 minute ago';
      } else if (diffInMinutes >= 1 && diffInMinutes < 2) {
        timeAgo = '1 minute ago';
      } else if (diffInMinutes >= 2 && diffInMinutes < minutesInHour) {
        timeAgo = `${diffInMinutes} minutes ago`;
      } else if (diffInMinutes >= minutesInHour && diffInMinutes < minutesInTwoHours) {
        timeAgo = '1 hour ago';
      } else if (diffInMinutes >= minutesInTwoHours && diffInMinutes < minutesInDay) {
        timeAgo = `${Math.floor(diffInMinutes / minutesInHour)} hours ago`;
      } else if (diffInMinutes >= minutesInDay && diffInMinutes < minutesInTwoDays) {
        timeAgo = '1 day ago';
      } else {
        timeAgo = `${Math.floor(diffInMinutes / minutesInDay)} days ago`;
      }

      // example: "... up 425% even after the stock's big wipeout. - Markets Insider" ->
      // titleWithoutSource: "... up 425% even after the stock's big wipeout."
      const indexOfSource = item.title.indexOf(`- ${item.source}`);
      let titleWithoutSource = item.title.slice(0, indexOfSource).trim();

      // remove ",", "," from the end of the title
      if ([',', '.'].includes(titleWithoutSource.slice(-1))) {
        titleWithoutSource = titleWithoutSource.slice(0, -1);
      }

      return { ...item, timeAgo, titleWithoutSource };
    });

    // There can be 2 or more same news from different sources - keep only one
    // Group news from different sources that are consecutive in the sorted by pubDate array
    const groupedNews: GroupedCompanyNewsModel[] = [];
    let currentGroup = [extendedNews[0]]; // Start with first item if available

    for (let i = 1; i < extendedNews.length + 1; i++) {
      const current = extendedNews[i];
      const previous = extendedNews[i - 1];

      if (current && current.titleWithoutSource.toLowerCase() === previous.titleWithoutSource.toLowerCase()) {
        // Add to current group if titles match and they're consecutive
        currentGroup.push(current);
      } else {
        // If titles don't match, save current group and start a new one
        if (currentGroup.length > 0 && currentGroup[0]) {
          const baseItem = currentGroup[0];

          groupedNews.push({
            id: baseItem.id,
            title: baseItem.title,
            description: baseItem.description,
            titleWithoutSource: baseItem.titleWithoutSource,
            sources: currentGroup
              .filter((item) => Boolean(item))
              .map((item) => ({
                id: crypto.randomUUID(),
                source: item.source,
                sourceLink: item.sourceLink,
                url: item.url,
                pubDate: item.pubDate,
                timeAgo: item.timeAgo,
              })),
          });
        }

        currentGroup = current ? [current] : [];
      }
    }

    return { status: news.status, news: groupedNews };
  });

  constructor(
    private injector: Injector,
    private newsService: NewsService,
    private marketTimeService: MarketTimeService,
  ) {}

  ngOnInit(): void {}

  public updateNews(): void {
    const currentNews = this.currentNews();

    // do not re-request news if it is waiting for the response
    if (currentNews.status === 'pending') {
      return;
    }

    this.updateNewsSignal$.next(Date.now());
  }

  protected onClickLink(link: string): void {
    if (!link) {
      return;
    }

    window.open(link, '_blank');
  }
}
