<div class="news-tabs-container scroll-style">
  @let newsDetails = currentNews();
  @let showNews = newsDetails.status === 'success' && newsDetails.news.length > 0;
  @let showNoData =
    newsDetails.status === 'error' || (newsDetails.status === 'success' && newsDetails.news.length === 0);

  @if (newsDetails.status === 'pending') {
    <div class="spinner-container">
      <mat-spinner
        class="blue-spinner"
        diameter="50"
      ></mat-spinner>
    </div>
  }

  @if (showNews) {
    <div class="rocky-tabs-container scroll-style">
      @for (item of newsDetails.news; track item.id) {
        <div class="news-item">
          <div class="item-rocky-title">
            {{ item.title }}
          </div>
          <div class="item-description">
            {{ item.summary }}

            <span class="links-container">
              @for (link of item.indexedLinks; track link.linkIndex) {
                <span
                  (click)="onClickLink(link.link)"
                  class="link"
                >
                  {{ link.linkIndex }}
                </span>
              }
            </span>
          </div>
        </div>
      }
    </div>
  }

  @if (showNoData) {
    <div class="no-data-wrapper">
      <div class="no-data-label">No data found.</div>
      <div class="no-data-image"></div>
    </div>
  }
</div>
