import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ProgressBarModule } from '@c/common/progress-bar/progress-bar.module';
import { health, profitability, valuation } from './fundamentals.data';

@Component({
  selector: 'app-fundamentals',
  standalone: true,
  imports: [ProgressBarModule, NgTemplateOutlet],
  templateUrl: './fundamentals.component.html',
  styleUrl: './fundamentals.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundamentalsComponent {
  protected readonly valuations = valuation;
  protected readonly healths = health;
  protected readonly profitabilities = profitability;
}
