<div class="fundamentals-container scroll-data">
  <h3 class="fundamentals-title">Fundamentals</h3>
  <div class="fundamental">
    <div class="header">
      <p>Valuation</p>
      <p>Industry Rank</p>
      <p>Sector Rank</p>
    </div>
    <div class="fundamental-wrapper">
      @for (valuation of valuations; track valuation.title) {
        <ng-container *ngTemplateOutlet="fundamentalRow; context: { item: valuation }"></ng-container>
      }
    </div>
  </div>
  <div class="fundamental">
    <div class="header">
      <p>Health</p>
    </div>
    <div class="fundamental-wrapper">
      @for (health of healths; track health.title) {
        <ng-container *ngTemplateOutlet="fundamentalRow; context: { item: health }"></ng-container>
      }
    </div>
  </div>
  <div class="fundamental">
    <div class="header">
      <p>Profitability</p>
    </div>
    <div class="fundamental-wrapper">
      @for (profitability of profitabilities; track profitability.title) {
        <ng-container *ngTemplateOutlet="fundamentalRow; context: { item: profitability }"></ng-container>
      }
    </div>
  </div>
</div>

<ng-template
  #fundamentalRow
  let-item="item"
>
  <div class="fundamental-row">
    <div class="description">
      <h3>{{ item.title }}</h3>
      <p>{{ item.description }}</p>
    </div>
    <div class="industry">
      <p>
        <b>{{ item.industry }}</b>
      </p>
      <app-progress-bar
        [barColor]="item.industry > 30 ? 'success' : 'danger'"
        [value]="item.industry"
        class="bar"
      ></app-progress-bar>
    </div>
    <div>
      <app-progress-bar
        [barColor]="item.sector > 30 ? 'success' : 'danger'"
        [value]="item.sector"
        class="bar"
      ></app-progress-bar>
    </div>
  </div>
</ng-template>
