<div class="news-tabs-container scroll-style">
  @let newsDetails = currentNews();
  @let showNews = newsDetails.status === 'success' && newsDetails.news.length > 0;
  @let showNoData =
    newsDetails.status === 'error' || (newsDetails.status === 'success' && newsDetails.news.length === 0);

  @if (newsDetails.status === 'pending') {
    <div class="spinner-container">
      <mat-spinner
        class="blue-spinner"
        diameter="50"
      ></mat-spinner>
    </div>
  }

  @if (showNews) {
    @for (item of newsDetails.news; track item.id) {
      <div
        (click)="onClickLink(item.sources[0].url)"
        class="news-item"
      >
        <div class="item-title">
          <span class="news-title">{{ item.sources[0].source }}</span>
          &#8226;
          <span class="news-timing">{{ item.sources[0].timeAgo }}</span>
        </div>
        <div class="item-secondary-title">
          {{ item.titleWithoutSource }}
        </div>
      </div>
    }
  }

  @if (showNoData) {
    <div class="no-data-wrapper">
      <div class="no-data-label">No data found.</div>
      <div class="no-data-image"></div>
    </div>
  }
</div>
