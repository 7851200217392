<div
  class="chart-trading-option"
  id="tabb"
>
  <div class="left-side-button">
    <mat-button-toggle-group
      [value]="chartTab"
      (change)="changeChartTab(group.value)"
      hideSingleSelectionIndicator="true"
      name="fontStyle"
      #group="matButtonToggleGroup"
    >
      <mat-button-toggle
        [value]="ChartTabs.Signal"
        class="s-less-991"
      >
        {{ getChartTabName(ChartTabs.Signal) }}
      </mat-button-toggle>
      <mat-button-toggle [value]="ChartTabs.Chart">{{ getChartTabName(ChartTabs.Chart) }}</mat-button-toggle>
      <mat-button-toggle [value]="ChartTabs.TradingReport">
        {{ getChartTabName(ChartTabs.TradingReport) }}
      </mat-button-toggle>
      <mat-button-toggle value="{{ ChartTabs.Options }}">{{ getChartTabName(ChartTabs.Options) }}</mat-button-toggle>
      <mat-button-toggle value="{{ ChartTabs.Earnings }}">{{ getChartTabName(ChartTabs.Earnings) }}</mat-button-toggle>
      <mat-button-toggle value="{{ ChartTabs.Dividends }}">{{
        getChartTabName(ChartTabs.Dividends)
      }}</mat-button-toggle>
      <mat-button-toggle value="{{ ChartTabs.News }}">{{ getChartTabName(ChartTabs.News) }}</mat-button-toggle>
      <mat-button-toggle value="{{ ChartTabs.Fundamentals }}"
        >{{ getChartTabName(ChartTabs.Fundamentals) }}
      </mat-button-toggle>
      <mat-button-toggle value="{{ ChartTabs.Technical }}">{{
        getChartTabName(ChartTabs.Technical)
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div
    [class.trading-report-container]="chartTab === ChartTabs.TradingReport"
    class="right-side-content"
  >
    <app-data-window
      [isPrint]="false"
      *ngIf="chartTab === ChartTabs.Signal"
    ></app-data-window>

    <app-trading-chart *ngIf="chartTab === ChartTabs.Chart"></app-trading-chart>
    <!-- <app-trading-chart-v0 *ngIf="chartTab === ChartTabs.Chart"></app-trading-chart-v0> -->

    <app-trading-report
      [isPrint]="false"
      *ngIf="chartTab === ChartTabs.TradingReport"
    ></app-trading-report>
    <app-options
      [isPrint]="false"
      *ngIf="chartTab == ChartTabs.Options"
    ></app-options>
    <app-earnings-analysis-content
      [isChartTab]="true"
      *ngIf="chartTab == ChartTabs.Earnings"
    ></app-earnings-analysis-content>
    <app-dividends-strategy-dividends-list
      *ngIf="chartTab == ChartTabs.Dividends"
    ></app-dividends-strategy-dividends-list>
    <div
      *ngIf="chartTab == ChartTabs.News"
      class="news"
    >
      <div class="company-news news-block">
        <h3 class="header-news">News</h3>
        <app-company-news
          [isActive]="true"
          [companyName]="companyNameForAiSummary"
        ></app-company-news>
      </div>
      <div class="ai-news news-block">
        <span class="ai-news-header header-news">
          <mat-icon svgIcon="cody-chat"></mat-icon>
          <h3>Rocky Insights</h3>
        </span>
        <app-ai-news
          [isActive]="false"
          [companyName]="companyNameForAiSummary"
        ></app-ai-news>
      </div>
    </div>
    <app-fundamentals *ngIf="chartTab == ChartTabs.Fundamentals"></app-fundamentals>
    <app-technical *ngIf="chartTab == ChartTabs.Technical"></app-technical>
  </div>
</div>
