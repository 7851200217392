import { Component, OnDestroy, OnInit } from '@angular/core';
import { SymbolsService } from '@s/symbols.service';
import { Subscription } from 'rxjs';
import { ChartTabs, getChartTabName, TabletMinWidth, UserSettings } from '../../../constants';
import { ObservableService } from '../../../services/observable.service';
import { UserDataService } from '../../../services/user-data.service';

@Component({
  selector: 'app-chart-menu',
  templateUrl: './chart-menu.component.html',
  styleUrls: ['./chart-menu.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)',
  },
})
export class ChartMenuComponent implements OnInit, OnDestroy {
  chartTab: string = '';
  ChartTabs = ChartTabs;
  getChartTabName = getChartTabName;
  protected companyNameForAiSummary: string;
  private subscription = new Subscription();

  constructor(
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private symbolsService: SymbolsService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.observableService.symbol.subscribe(async (symbol) => {
        const symbolDetails = await this.symbolsService.getById(symbol);
        this.companyNameForAiSummary = symbolDetails.description + ' (' + symbolDetails.symbol + ')';
      }),
    );
    this.chartTab = this.observableService.chartTab.getValue();
    this.onWindowResize();
    this.subscription.add(this.observableService.chartTab.subscribe((chartTab) => (this.chartTab = chartTab)));
  }

  async changeChartTab(chartTab): Promise<void> {
    await this.userDataService.set(UserSettings.ChartTab, chartTab);
  }

  async onWindowResize(event = null): Promise<void> {
    const newWidth = event ? event.target.innerWidth : window.innerWidth;
    if (newWidth > TabletMinWidth && this.chartTab === ChartTabs.Signal) {
      await this.userDataService.set(UserSettings.ChartTab, ChartTabs.Chart);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
