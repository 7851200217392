export const technicalData = {
  shortTermTrend: 'high',
  mediumTermTrend: 'low',
  longTermTrend: 'medium',
  correlation: 0.15,
  ranges: {
    weekLow: 164.01,
    weekHigh: 260.1,
    dailtyRange: 1.66,
    averageVolume: '7.06M',
  },
  performance: [
    {
      range: 'W1',
      value: -5.31,
    },
    {
      range: 'M1',
      value: -12.16,
    },
    {
      range: 'M3',
      value: -15.96,
    },
    {
      range: 'M6',
      value: -5.81,
    },
    {
      range: 'YTD',
      value: -14.87,
    },
    {
      range: 'Y1',
      value: 20.7,
    },
  ],
};
