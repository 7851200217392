export enum DirectionClass {
  high = 'high',
  medium = 'medium',
  low = 'low',
}

export enum PerformanceName {
  W1 = '1 week',
  M1 = '1 month',
  M3 = '3 months',
  M6 = '6 months',
  YTD = 'Year to date',
  Y1 = '1 year',
}
