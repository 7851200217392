import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Response } from '@core1/interface';
import { environment } from '@env/environment';
import { MemoryCacheService } from '@s/cache-utils/memory-cache.service';
import { UserDataService } from '@s/user-data.service';

export interface NewsItemModel {
  id: string;
  title: string;
  url: string | null;
  description: string;
  pubDate: string;
  source: string;
  sourceLink: string;
}

export interface AiNewsResponseModel {
  data: {
    output: string; // JSON
  };
}

interface GroundingChunkModel {
  web: {
    title: string;
    uri: string;
  };
  retrieved_context: null;
}

interface GroundingSupportModel {
  confidence_scores: number[];
  grounding_chunk_indices: number[];
  segment: {
    end_index: number;
    part_index: null | number;
    start_index: null | number;
    text: string;
  };
}

export interface AiNewsItemStreamResponseModel {
  text: string;
  grounding_chunks: GroundingChunkModel[];
  grounding_supports: GroundingSupportModel[];
}

export interface AiNewsStreamResponseEventModel {
  fullResponse: AiNewsItemStreamResponseModel[] | null;
  item: AiNewsItemStreamResponseModel;
  currentNews: AiNewsItemModel[];
}

export interface AiNewsItemModel {
  id: string;
  title: string;
  publication_date: string;
  summary: string;
  links: string[];
  link_indices: number[];
}

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private currentAiNewsStreamAbortController: AbortController | null = null;

  constructor(
    private http: HttpClient,
    private memoryCacheService: MemoryCacheService,
    private userDataService: UserDataService,
  ) {}

  // it returns XML in the response
  public getNews(companyName: string): Observable<NewsItemModel[]> {
    const cacheTtl = 1000 * 60 * 5; // 5 minutes
    const cacheKey = `news-${companyName}`;

    const cachedValue = this.memoryCacheService.get<NewsItemModel[]>(cacheKey);

    if (cachedValue) {
      return of(cachedValue);
    }

    return this.http.get<Response<{ data: string }>>(`/v2/companyNews/google?company_name=${companyName}`).pipe(
      map((response) => {
        try {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response.result.data, 'application/xml');
          const items = xml.querySelectorAll('item');
          const news: NewsItemModel[] = [];

          items.forEach((item) => {
            news.push({
              id: item.querySelector('guid')?.textContent ?? crypto.randomUUID(),
              title: item.querySelector('title')?.textContent ?? '',
              url: item.querySelector('link')?.textContent ?? null,
              description: item.querySelector('description')?.textContent ?? '',
              pubDate: item.querySelector('pubDate')?.textContent ?? '',
              source: item.querySelector('source')?.textContent ?? null,
              sourceLink: item.querySelector('source')?.attributes?.getNamedItem('url')?.value ?? null,
            });
          });

          return news;
        } catch (error) {
          console.error(`Error getting and parsing XML from RSS (google news): ${error}`);
          return [];
        }
      }),
      catchError(() => of([])),
      tap((news) => this.memoryCacheService.set(cacheKey, news, cacheTtl)),
    );
  }

  public getAiNewsAsStream(
    companyName: string,
    startDate: string,
    endDate: string,
  ): Observable<AiNewsStreamResponseEventModel> {
    this.abortCurrentAiNewsStreamRequest();

    return this.getAiNewsStream(companyName, startDate, endDate);
  }

  private getAiNewsStream(
    companyName: string,
    startDate: string,
    endDate: string,
  ): Observable<AiNewsStreamResponseEventModel> {
    this.currentAiNewsStreamAbortController = new AbortController();
    const signal = this.currentAiNewsStreamAbortController.signal;

    return new Observable((observer) => {
      // important: http service does not support streaming
      fetch(environment.BaseUrl + '/v2/ai/news', {
        method: 'POST',
        headers: {
          Accept: 'text/event-stream',
          'Content-Type': 'application/json',
          Authorization: this.userDataService.getAuthToken(),
        },
        body: JSON.stringify({ company_name: companyName, start_date: startDate, end_date: endDate }),
        signal,
      })
        .then((response) => {
          const reader = response.body?.getReader();

          if (!reader) {
            observer.error('No readable stream found.');

            return;
          }

          // Example of chunks received (keep it for debugging/refactoring):
          // [
          //   "{\"text\": \"*\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" **Analysts Warn of Potential Threats to Apple Stock in 2025.**\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" Apple's stock has declined 4.7% since the start of 20\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \"25, and analysts are concerned about its performance compared to the previous year. Factors such as underwhelming iPhone 16e sales due to stalling smartphone growth in\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" China and the potential impact of Trump-induced tariffs are contributing to this cautious outlook.\\n\\n* **Apple's Market Support Remains Robust Despite a Slight Dip in\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" Stock Price.** Despite a slight decrease in Apple's stock price in early March 2025, market support from retail investors, analysts, and institutions remains strong. Institutional activity has been notably high, with significant buying in Q1\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" 2025, and analysts maintain a bullish outlook with positive revisions and upgrades.\\n\\n* **Apple's Share Price Experiences a Slight Increase Amidst Moderate Buy Rating.** On March 6, 2025, Apple Inc\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \".'s share price increased by 0.5%, trading as high as $237.86. The stock maintains a \\\"Moderate Buy\\\" rating with a consensus price target of $243.88, reflecting positive sentiment from a majority of analysts.\\n\\n* **New MacBook Air and Mac Studio Un\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \"veiled with M4 and M3 Ultra Chips.** Apple has introduced the new MacBook Air, featuring the M4 chip, and the new Mac Studio, powered by M4 Max and the M3 Ultra chip. These new products boast enhanced performance and features, potentially impacting investor sentiment and stock performance.\\n\\n* **Strategic\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" Shift Towards AI Could Bolster Apple's Competitive Edge.** Apple recently announced a substantial investment of $500 billion in the U.S., focusing on artificial intelligence and server technologies, rather than increasing iPhone manufacturing. This strategic shift towards artificial intelligence capabilities could bolster Apple's competitive edge and potentially enhance its market valuation in\", \"grounding_chunks\": [], \"grounding_supports\": []}\n",
          //   "{\"text\": \" the long term.\\n\", \"grounding_chunks\": [{\"retrieved_context\": null, \"web\": {\"title\": \"watcher.guru\", \"uri\": \"https://vertexaisearch.cloud.google.com/grounding-api-redirect/AQXblrwtUNHznSy_33cfPczL4Q8AOAeLpchbS_IFu2Oc8eDsWHNLPvifjl3MSKkL2ZnDXrak03A2vfdd8WX2ZSh2KnLrN1HWIbN5wGa0uAHOJLT_Et1GUAI0k4-OE5F0J4cbXQUOZg0Hq6tJ-yCz011X1ybwXkv61gtyPsIBR8gRxvN4bsYp4OISkP4sjQ2oHE6Myw==\"}}, {\"retrieved_context\": null, \"web\": {\"title\": \"entrepreneur.com\", \"uri\": \"https://vertexaisearch.cloud.google.com/grounding-api-redirect/AQXblrz8VFkPuxw7O0CFgrdaHrk-37EseJm8dte-Npkk22jun5UZDpdjuTeA0guhN8BPflKhcNhB6KdG2-gLf9wGN2t5y0urvSrR7GU4GBcfJi5UL2tG0tnZ073Gg0qkRmmHQ8W6wkPU0VP3uRt-QdxIf7vUGmOkOFxLKtqCo4eNLD6Qr35Zcaun_clQaadttLgX6ddb7p808uvCd0nBLcBQUlTS\"}}, {\"retrieved_context\": null, \"web\": {\"title\": \"tradingview.com\", \"uri\": \"https://vertexaisearch.cloud.google.com/grounding-api-redirect/AQXblrx2KcMJvdVdXcmfY8eqd8W_jy9YUkoeuKC2qvXrDkbhNIabTXE7Ar9oDFtQG3Eq4go0kDFHq6zhEKsXZc7Vj_LkQO5oPHr5Zhaf9nrhm85A27bbmELWz131dJ-khPF6OU9ZAHHTBWx0xa4eTcG2oTCFGTXw9r_ywYfrktAv2hR1B4475kfFqb_4CKEx96kNoZGDekz9kq44bPF8rsPo_4whLiLSalfKX9-fEPVZbhgBMkw=\"}}, {\"retrieved_context\": null, \"web\": {\"title\": \"apple.com\", \"uri\": \"https://vertexaise",
          //   "arch.cloud.google.com/grounding-api-redirect/AQXblrw8A0DHpN2_JiteZIYqoLB3WAbwo-xGA6ZLkSWEsXLxnUDBArufbQjgW-tgB6C7jjC1FCnrSUOkV64LyC9uiBKkUNMddqgJmVJgabh1661JBdAHz2sY_5Poep5SMJRovFMco2n4EQT40AIZ8RTTYwjFwRbGgKk=\"}}, {\"retrieved_context\": null, \"web\": {\"title\": \"youtube.com\", \"uri\": \"https://vertexaisearch.cloud.google.com/grounding-api-redirect/AQXblrxbhJOYEXDJUgDw_6l1Qsqji56GyZaZmMptwVi0r9C8dqQ2DAG-fP0m0kMzOnIbzXMlP_FpVEpk4xV2RtwkZhAA_iRy_tl-3EK0bEhGbgrBmZWdMhqPxmT-QhErrU2zZ7KknX40Jwg=\"}}], \"grounding_supports\": [{\"confidence_scores\": [0.8803516], \"grounding_chunk_indices\": [0], \"segment\": {\"end_index\": 204, \"part_index\": null, \"start_index\": null, \"text\": \"*  **Analysts Warn of Potential Threats to Apple Stock in 2025.**  Apple's stock has declined 4.7% since the start of 20 25, and analysts are concerned about its performance compared to the previous year.\"}}, {\"confidence_scores\": [0.8624322], \"grounding_chunk_indices\": [0], \"segment\": {\"end_index\": 388, \"part_index\": null, \"start_index\": 205, \"text\": \"Factors such as underwhelming iPhone 16e sales due to stalling smartphone growth in  China and the potential impact of Trump-induced tariffs are contributing to this cautious outlook.\"}}, {\"confidence_scores\": [0.9819494, 0.96588755], \"grounding_chunk_indices\": [1, 2], \"segment\": {\"end_index\": 622, \"part_index\": null, \"start_index\": 390, \"text\": \"* **",
          //   "Apple's Market Support Remains Robust Despite a Slight Dip in  Stock Price.** Despite a slight decrease in Apple's stock price in early March 2025, market support from retail investors, analysts, and institutions remains strong.\"}}, {\"confidence_scores\": [0.93523115, 0.9504811], \"grounding_chunk_indices\": [1, 2], \"segment\": {\"end_index\": 783, \"part_index\": null, \"start_index\": 623, \"text\": \"Institutional activity has been notably high, with significant buying in Q1  2025, and analysts maintain a bullish outlook with positive revisions and upgrades.\"}}, {\"confidence_scores\": [0.9017281], \"grounding_chunk_indices\": [3], \"segment\": {\"end_index\": 1310, \"part_index\": null, \"start_index\": 1106, \"text\": \"* **New MacBook Air and Mac Studio Un veiled with M4 and M3 Ultra Chips.** Apple has introduced the new MacBook Air, featuring the M4 chip, and the new Mac Studio, powered by M4 Max and the M3 Ultra chip.\"}}, {\"confidence_scores\": [0.99018425], \"grounding_chunk_indices\": [4], \"segment\": {\"end_index\": 1694, \"part_index\": null, \"start_index\": 1436, \"text\": \"* **Strategic  Shift Towards AI Could Bolster Apple's Competitive Edge.** Apple recently announced a substantial investment of $500 billion in the U.S., focusing on artificial intelligence and server technologies, rather than increasing iPhone manufacturing.\"}}, {\"confidence_scores\": [0.9895224], \"grounding_chunk_indices\": [4], \"segment\": {\"end_index\": 1863, \"part_index\": null, \"start_index\": 1695, \"text\": \"This strategic shift towards artificial intelligence capabilities could bolster Apple's competitive edge and potentially enhance its market valuation in  the long term.\"}}]}\n"
          // ]
          //
          // It can be also JSON String - result of JSON.stringify(<AiNewsItemStreamResponseModel>) - from already joined chunks (in case it is taken from the cache)
          // the handler should work with both cases

          const decoder = new TextDecoder();
          let buffer = '';
          const newsItems = [];

          const readChunk = (): void => {
            reader
              .read()
              .then(({ done, value }) => {
                if (done) {
                  // Try to process any remaining data in the buffer
                  if (buffer.trim()) {
                    try {
                      const parsedItem = JSON.parse(buffer);

                      if (parsedItem && typeof parsedItem === 'object') {
                        newsItems.push(parsedItem);
                      }
                    } catch {
                      // Ignore parsing errors for incomplete data
                    }
                  }

                  const news = {
                    fullResponse: newsItems,
                    currentNews: this.getNewsFromPartialStream(newsItems, true),
                    item: null,
                  };

                  // Stream is complete, emit the full array of collected objects
                  observer.next(news);
                  observer.complete();

                  return;
                }

                // Append new chunk to buffer
                const chunk = decoder.decode(value, { stream: true });
                buffer += chunk;

                // Process complete JSON objects
                let startPos = 0;
                let endPos = buffer.indexOf('\n', startPos);

                while (endPos !== -1) {
                  const jsonLine = buffer.substring(startPos, endPos).trim();

                  if (jsonLine) {
                    try {
                      const parsedItem = JSON.parse(jsonLine);

                      if (parsedItem && typeof parsedItem === 'object') {
                        newsItems.push(parsedItem);
                        // Emit each valid object individually
                        observer.next({
                          fullResponse: newsItems,
                          currentNews: this.getNewsFromPartialStream(newsItems, false),
                          item: parsedItem,
                        });
                      }
                    } catch (e) {
                      console.warn('Failed to parse JSON chunk:', e);
                    }
                  }

                  // Move to the next line
                  startPos = endPos + 1;
                  endPos = buffer.indexOf('\n', startPos);
                }

                // Keep the remainder for the next iteration
                buffer = startPos < buffer.length ? buffer.substring(startPos) : '';

                // Continue reading
                readChunk();
              })
              .catch((error) => observer.error(error));
          };

          readChunk();
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            observer.complete();
          } else {
            observer.error(error);
          }
        });

      return () => {
        // Clean up if the subscriber unsubscribes
        if (this.currentAiNewsStreamAbortController) {
          this.currentAiNewsStreamAbortController.abort();
          this.currentAiNewsStreamAbortController = null;
        }
      };
    });
  }

  private getNewsFromPartialStream(newsStreamItems: AiNewsItemStreamResponseModel[], done: boolean): AiNewsItemModel[] {
    const fullText = newsStreamItems
      .map((item) => {
        return item.text;
      })
      .join('');

    let allGroundingChunks: GroundingChunkModel[] = [];
    let allGroundingSupports: GroundingSupportModel[] = [];

    if (done) {
      allGroundingChunks = newsStreamItems.flatMap((item) => item.grounding_chunks);
      allGroundingSupports = newsStreamItems.flatMap((item) => item.grounding_supports);
    }

    const news = fullText.split('\n\n').map((item) => {
      const text = item;
      const link_indices: number[] = [];

      if (allGroundingChunks.length > 0 && allGroundingSupports.length > 0) {
        allGroundingSupports.forEach((groundingSupportItem) => {
          // Example:
          // "* **UBS Reaffirms Neutral Rating for Apple Inc.** UBS has reaffirmed its neutral rating for Apple stock, maintaining its target price at $236. This indicates that UBS's research aligns with their existing advice on the stock."
          // "*  **UBS Re affirms Neutral Rating for Apple Inc.** UBS has reaffirmed its neutral rating for Apple stock , maintaining its target price at $236."
          const isContains = text.replace(/\s+/g, '').includes(groundingSupportItem.segment.text.replace(/\s+/g, ''));

          if (isContains) {
            const groundingChunkIndices = groundingSupportItem.grounding_chunk_indices;
            link_indices.push(...groundingChunkIndices);
          }
        });
      }

      return { text, link_indices: Array.from(new Set(link_indices)).sort((a, b) => Number(a) - Number(b)) };
    });

    return news.map((item) => {
      // Example:
      // "* **Microsoft's Dragon Copilot Aims to Revolutionize Healthcare with AI Voice Assistance.** Microsoft announced Dragon Copilot, a unified voice AI assistant for the healthcare industry, designed to streamline clinical documentation and automate tasks. Scheduled for release in the U.S. and Canada in May 2025, Dragon Copilot has already shown promising results in over 600 healthcare organizations by improving workflows and saving time per patient."

      // Match format: "* **Header.** Content" or "* **Header** Content"
      const headerRegex = /^\s*\*\s*\*\*(.*?)\*\*(?:\.|)\s*(.*)/;
      const match = item.text.match(headerRegex);

      let header = '';
      let content = '';

      if (match) {
        header = match[1].trim();
        content = match[2] ? match[2].trim() : '';
      } else {
        // Fallback for partial matches or different formats
        const partialHeaderRegex = /^\s*\*\s*\*\*(.*?)$/;
        const partialMatch = item.text.match(partialHeaderRegex);

        if (partialMatch) {
          header = partialMatch[1].trim();
        } else {
          // If no regex matches, use the whole text as content
          content = item.text.trim();
        }
      }

      const linksToAdd = item.link_indices.map((index) => allGroundingChunks[index].web.uri);

      return {
        id: header,
        title: header,
        summary: content,
        publication_date: '',
        links: [...linksToAdd],
        link_indices: [...item.link_indices.map((item) => item + 1)],
      };
    });
  }

  private abortCurrentAiNewsStreamRequest(): void {
    if (this.currentAiNewsStreamAbortController) {
      this.currentAiNewsStreamAbortController.abort();
      this.currentAiNewsStreamAbortController = null;
    }
  }
}
